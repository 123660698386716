@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');

:root {
    
    --primary-default: #4341d9;
    --primary-hovered: #3634af;
    --primary-active: #4441d9;
    --primary-disabled: #c6c5ff;

    --primary-default-green: #49c95f;
    --primary-hovered-green: #62d075;
    --primary-active-green: #2dbe46;
    --primary-disabled-green: #a4e9b0;


    --secondary-default: #ff964f;
    --secondary-hovered: #ffa466;
    --secondary-active: #f9802f;
    --secondary-disabled: #ffcaa7;
    --surface-main: #fff;
    --surface-secondary: #f9f9fa;
    --surface-default: #f3f5f7;
    --surface-hovered: #f9f9fa;
    --text-main: #292e33;
    --text-secondary: #454e57;
    --text-secondary-subdued: #8696a6;
    --text-on-primary: #fff;
    --border-default: #d4dae0;
    --border-subdued: #f3f5f7;
    --critical-default: #ff4f64;
    --critical-highlight: #ffc2ca;
    --critical-surface: #fef5f5;
    --warning-default: #fee14b;
    --warning-highlight: #fff9e9;
    --warning-surface: #fff9e9;
    --success-default: #49c95f;
    --success-highlight: #b0e8ba;
    --success-surface: #f2fcf4;
    --safe: #1975ff;
    --safe-surface: #f0f5fe;
    --discount: #ff4f64;
    --rating: #d4b444;
    --black-color: #000;
    --button-paypal-color: #ffc439;
    --logo-color-fast: #252d48;
    --brand-primary-female-fast: #f09f8f;
    --background-color-fast: #f2efee;
    --text-secondary-fast: #9296a3;
    --experiment-purpleLight: #f6f6fd;
    --experiment-sandLight: #fdfaed;
    --grey-ligt: #c4c4c4;
    --background-subdude: #f2f2f2;
    --border-subdued2: #e9ecef;
    --default-indent: 16px;
    --fixed-container-indent: 128px;
    --default-indent-double: calc(var(--default-indent) * 2);
    --header-height: 67px;
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;
    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
    --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
    --white-color: #fff;
    --black-color: #000;
    --stroke-color: #d4dae0;
    --highlights: #fbfefb;
    --highlights-error: snow;
    --error-color: #f16b6b;
    --success-color: #f09f8f;
    --promotion-color: #ff4f64;
    --button-paypal-color: #ffc439;
    --default-unit-input: #a6b3e4;
    --cookie-background: #f2fcf4;
    --default-indent: 16px;
    --default-indent-double: calc(var(--default-indent)*2);
    --logo-color-fast: #252d48;
    --brand-primary-female-fast: #f09f8f;
    --background-color-fast: #f2efee;
    --text-secondary-fast: #9296a3;
    --safeLight: #1975ff;
    --grey-ligt: #c4c4c4;
    --text-main-secondary: #292e33;
    --background-subdude: #f2f2f2;
    --border-default: #d6d7db;
    --border-subdued: #e9eaed;
    --brand-primary: #f09f8f;
    --brand-primary-disabled: #efbcaf;
    --background-color: #fff;
    --footer-background-color: #f9f9fa;
    --option-border-color: #d4dae0;
    --option-background-color: #fff;
    --default-text-color: #454e57;
    --hint-color: #454e57;
    --label-color: #8696a6;
    --highlighted-label-color: #df7373;
    --hint-bg: #f9fafb;
    --brand-primary-opacity-5: rgb(73 212 97/5%);
    --logo-color: #454e57;
    --box-border-color: #d4dae0;
    --hint-background: #f2fcf4;
    --multioption-check-background: #d4dae0;
    --fasting-option-background: #f3f5f7;
    --help-icon-background: #8696a6;
    --modal-payment-background: #28292b;
    --modal-text-background: #f9f9fa;
    --experiment-purpleLight: #f6f6fd;
    --critical-highlight: #fef5f5;
    --guarantee-block: #f0f5fe;
    --decorative-male: #92c3ec;
    --facebook-blue: #1877f2;
    --facebook-hover-blue: #005eba;
    --facebook-hover-white: #f2efee;
    --dark-background-color: #343f65;
    --app-height: 100%;
    --lib-secondary-default: #252d48;
    --lib-surface-secondary: #f2efee;
    --lib-surface-default: #f2efee;
    --lib-success-surface: #f5faf7;
    --lib-warning-surface: #fefbe7;
    --lib-critical-surface: #fcf3f3;
    --lib-surface-main: #fff;
    --lib-on-primary: #fff;
    --lib-text-secondary: #767a86;
    --lib-text-secondary-subdued: #767a86;
    --lib-primary-hovered: #ee8f7d;
    --lib-warning-highlight: #fbf4b6;
    --lib-warning-default: #f7ec7c;
    --lib-primary-active: #ec8774;
    --lib-surface-hovered: #e9e4e2;
    --lib-primary-default: #f09f8f;
    --lib-border-default: #d6d7db;
    --lib-text-main: #252d48;
    --lib-success-highlight: #cbe2d7;
    --lib-decorative-safe: #b7d4ec;
    --lib-decorative-safe-surface: #d4ceea;
    --lib-decorative-rating: #ddcdd0;
    --lib-primary-disabled: #f5bdb3;
    --lib-success-default: #7fb89d;
    --lib-decorative-discount: #64b990;
    --lib-border-subdued: #e9eaed;
    --lib-action-disabled: #c1c4cc;
    --lib-action-pressed: #1d2439;
}

html {
    font-family: "Roboto", sans-serif;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

button {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}

a {
    text-decoration: none;
    color: inherit;
}

.intro {
    padding: 50px 20px;
}

.container {
    max-width: 970px;
    margin: 0 auto;
}

.intro h1 {
    font-size: 28px;
}

.text-big {
    font-size: 20px;
    margin: 20px 0;
}


#loader {
    align-items: center;
    background: #49d461;
    display: flex;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
}

#loader__img {
    animation: loaderPulseAnimation 1s infinite;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
}

@keyframes loaderPulseAnimation {
    30% {
        transform: translateY(-50%) scale(1);
    }

    50% {
        transform: translateY(-50%) scale(1.2);
    }
}

.header__headerModule {
    background-color: var(--surface-main);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.headerModule__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80px;
    padding: 15px;
    position: relative;
}

.headerModule__top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.headerModule__backIcon {
    cursor: pointer;
}

.headerModule__logo {
    align-items: center;
    color: var(--border-default);
    display: flex;
    height: 28px;
    position: relative;
    text-align: center;
    z-index: 100;
}

.headerModule__pageCount {
    font-size: 12px;
    font-weight: 500;
}

.pageCount__start,
.pageCount__separator {
    color: var(--primary-default);
}

.pageCount__separator {
    margin: 0;
}

.pageCount__end {
    color: var(--text-main);
}

.headerModule__progressBox {
    background-color: rgba(212, 218, 224, .5);
    border-radius: 5px;
    height: 4px;
    position: relative;
    width: 100%;
}

.headerModule__progressBar {
    background-color: var(--primary-default);
    border-radius: 5px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: width .2s linear;
}

/* Gender */

.Gender-module-container--Bgddf {
    padding: 85px 15px 24px;
}

.gmLanq {
    font-family: "Syne", sans-serif;
    color: rgb(69, 78, 87);
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 12px;
}

.kFjGHT {
    font-family: "Poppins", sans-serif;
    color: rgb(134, 150, 166);
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 4px;
}

.Gender-module-pulse--\+XAo5 .Gender-module-genderChoice--YZO2v {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 45px;
}

.GenderOptionBox-module-optionWrapper---9vyi {
    align-items: baseline;
    display: flex;
    padding-bottom: 12px;
    padding-top: 20px;
    position: relative;
    margin: 0 auto;
}

.GenderOptionBox-module-optionWrapper---9vyi:before {
    content: "";
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: calc(100% + 30px);
}

.GenderOption-module-genderBox--A0WhC {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.GenderOption-module-genderImageBox--2MuRT {
    margin-bottom: 20px;
    max-width: 85%;
}

.GenderOption-module-genderImageBox--2MuRT img {
    max-width: 100%;
}

.LazyImage-module-image--pi4ov {
    display: block;
    height: auto;
    object-fit: contain;
    width: 100%;
}

.kxBUCB {
    position: relative;
    cursor: pointer;
    background-color: var(--primary-default);
    border-radius: 12px;
    color: rgb(255, 255, 255);
    width: 95%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    animation: 2s ease 0s infinite normal none running pulsar;
    box-shadow: var(--primary-default) 0px 0px 0px 0px;
}

@keyframes pulsar {
    100% {
        box-shadow: transparent 0px 0px 0px 14px;
    }
}

.dmpIyk {
    font-family: "Roboto", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.gAiOLN {
    position: relative;
    cursor: pointer;
    background-color: rgb(255, 150, 79);
    border-radius: 12px;
    color: rgb(255, 255, 255);
    width: 95%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    animation: 2s ease 0s infinite normal none running pulsar;
    box-shadow: rgb(255, 150, 79) 0px 0px 0px 0px;
}

.Common-module-centered--f1oqe {
    text-align: center !important;
}

.Text-module-transparence---YABR {
    opacity: .8;
}

.Text-module-grey--mn9R0 {
    color: var(--text-secondary-subdued) !important;
}

.Text-module-caption--MeFDb {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.Common-module-colorBrand--3FTX\+ {
    color: var(--primary-default) !important;
}

.Gender-module-loginLink--SnkVa {
    background: none;
    display: block;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 16px;
    position: absolute;
    right: 20px;
    text-transform: uppercase;
    top: 22px;
    z-index: 10;
    cursor: pointer;
}

@media (min-width: 768px) {
    .Gender-module-pulse--\+XAo5 .Gender-module-genderChoice--YZO2v {
        margin-bottom: 65px;
    }

    .GenderOption-module-genderBox--A0WhC {
        margin: 8px -4px 0;
        padding: 0 20px;
    }

    .GenderOption-module-genderImageBox--2MuRT {
        margin-bottom: 25px;
        max-width: 100%;
    }

    .kxBUCB,
    .gAiOLN {
        width: 100%;
    }

    .Gender-module-indexFooterContainer--4UjbA {
        margin: 0 auto;
        max-width: 410px;
    }
}


/* Step 3 */

.OptionContainer-module-container--Zu4Rp {
    padding: 50px 16px 24px;
}

@media screen and (min-width: 768px) {
    .OptionContainer-module-container--Zu4Rp {
        margin: 0 auto;
        max-width: calc(560px + var(--default-indent-double));
    }
}

.fLSGMF {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 24px;
}

.bOaSgr {
    position: relative;
    margin-bottom: 12px;
}

.cgDdUM {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
    opacity: 0;
    z-index: 100;
    cursor: pointer;
}

input {
    border: 0;
    border-radius: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    outline: none;
}

.cLGowB {
    padding: 14px 120px 14px 18px;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: stretch;
    box-sizing: border-box;
    border-radius: 12px;
    min-height: 80px;
    height: 96px;
    border: 1px solid rgb(212, 218, 224);
    background-color: rgb(255, 255, 255);
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-content: flex-start;
    justify-content: center;
}

.bOaSgr:hover>.cLGowB {
    background-color: var(--primary-active);
    border-color: var(--primary-active);
}

.UjSGr {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
}

.bskqQx {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    max-height: 56px;
}

.cnGAAS {
    position: absolute;
    width: 163px;
    min-width: 163px;
    max-width: 163px;
    height: 100%;
    bottom: 0px;
    top: -1px;
    right: -1px;
    margin-left: 5px;
}

.ipwqQP {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
}

/* Social Proof Module */

.socialProofModule {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: unset;
    padding: 40px var(--default-indent) 24px;
}

.socialProofModule__title {
    margin-bottom: 20px;
}

.socialProofModule__title h1 {
    font-family: "Roboto", sans-serif;
    color: var(--primary-default);
    text-align: center;
    font-size: 40px;
    line-height: 52px;
    font-weight: 700;
    margin-bottom: 16px;
}

.socialProofModule__title p {
    color: var(--text-secondary);
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-top: 8px;
    text-align: center;
}

.socialProofModule__imgWrapper {
    max-width: 600px;
}

.socialProofModule__img {
    max-width: 400px;
    height: auto;
    object-fit: contain;
    display: block;
}

.socialProofModule__stickyFooter {
    align-items: center;
    background: linear-gradient(0deg, var(--surface-main) 0, var(--surface-main) 65%, hsla(0, 0%, 100%, 0) 100%);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    margin: 0 auto;
    max-width: 432px;
    min-height: var(--fixed-container-indent);
    padding: 44px var(--default-indent) 20px;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 10;
}

.socialProofModule__nextBtn {
    position: relative;
    cursor: pointer;
    background-color: var(--primary-default);
    border-radius: 12px;
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 20px;
    min-height: 64px;
    margin-bottom: 20px;
    word-break: break-word;
    font-family: "Roboto", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

/* Height Module */

.OptionContainer-module-container--Zu4Rp {
    margin: 0 auto;
    max-width: calc(560px + var(--default-indent-double));
    padding: 50px 16px 24px;
}

.fbYsNG {
    position: relative;
    width: 100%;
    display: flex;
    max-width: 133px;
    background-color: rgb(249, 249, 250);
    border-radius: 8px;
    border: none;
    padding: 2px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 0px auto 7px;
}

.ctOqxH {
    position: relative;
    width: 100%;
    height: 24px;
    cursor: pointer;
    white-space: nowrap;
}

.loxZGu {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px 8px;
    border-radius: 6px;
}

.loxZGu:checked {
    background-color: var(--primary-default);
}

.eZLRYw {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 14px;
    transform: translate(-50%, -50%);
    font-family: "Roboto", sans-serif;
    font-size: 14px;
}

.loxZGu:checked+.sc-himrzO {
    color: rgb(255, 255, 255);
}

.ctOqxH {
    position: relative;
    width: 100%;
    height: 24px;
    cursor: pointer;
    white-space: nowrap;
}

.loxZGu {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px 8px;
    border-radius: 6px;
}

.loxZGu:checked {
    background-color: var(--primary-default);
}

.eZLRYw {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 14px;
    transform: translate(-50%, -50%);
    color: rgb(41, 46, 51);
    font-family: "Roboto", sans-serif;
    font-size: 14px;
}

.loxZGu:checked+.sc-himrzO {
    color: rgb(255, 255, 255);
}

input:not([type="range"]):not([type="color"]) {
    writing-mode: horizontal-tb !important;
}

.dKwJgB {
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 38px;
}

/* .TallContainer-module-inputMultiWrapper--RsUp0 div div:first-of-type, .TallContainer-module-inputMultiWrapper--RsUp0 div div:nth-of-type(2) {
    width: 50%;
} */
.bpSLXp:nth-child(1) {
    margin-right: 12px;
}

.bpSLXp {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.TallContainer-module-inputWrapper--nmVX6 label {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    width: 100%;
}

.TallContainer-module-inputMultiWrapper--RsUp0 div:first-of-type label {
    justify-content: flex-end;
}

.dAUrxE {
    font-family: "Roboto", sans-serif;
    max-width: 99.99%;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    margin-right: 4px;
    color: rgb(41, 46, 51);
    background-color: transparent;
    outline: none;
    text-align: right;
}

.dAUrxE::placeholder {
    color: rgb(219, 219, 219);
}

.lignQJ {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 5px;
    color: rgb(134, 150, 166);
}

.bpSLXp {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.hceQGH,
.lhLSYA {
    position: absolute;
    top: 56px;
    left: 0px;
    width: 100%;
}

.gKDiXa {
    font-family: "Roboto", sans-serif;
    color: rgba(255, 79, 100, 0);
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.fJrlsj {
    font-family: "Roboto", sans-serif;
    color: rgb(255, 79, 100);
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.ievZUR {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 48px;
}

.cwhRdA {
    font-family: "Roboto", sans-serif;
    max-width: 99.99%;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: rgb(41, 46, 51);
    background-color: transparent;
    outline: none;
    text-align: center;
}

.cwhRdA::placeholder {
    color: rgb(219, 219, 219);
}

.fbQbbV {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 5px;
    margin-left: 4px;
    color: rgb(134, 150, 166);
}

.hidden {
    display: none;
}

.showNote {
    color: rgb(134, 150, 166);
}

.TallContainer-module-feedbackWrapper--JdXvS {
    padding-bottom: 8px;
}

.PersonalFeedback-module-bgDefault--j9wWR {
    background: var(--surface-secondary);
}

.PersonalFeedback-module-container--yv4uS {
    border-radius: 8px;
    display: flex;
    padding: 8px;
}

.PersonalFeedback-module-imageWrapper--xkwWK {
    margin-right: 4px;
}

.PersonalFeedback-module-imageWrapper--xkwWK,
.PersonalFeedback-module-imageWrapper--xkwWK img {
    height: 20px;
    width: 20px;
}

.LazyImage-module-image--pi4ov {
    display: block;
    height: auto;
    object-fit: contain;
    width: 100%;
}

.PersonalFeedback-module-imageWrapper--xkwWK,
.PersonalFeedback-module-imageWrapper--xkwWK img {
    height: 20px;
    width: 20px;
}

.hBAgNn {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
}

.cgynBM {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-size: 12px;
    line-height: 16px;
}

.eRtgxg {
    position: relative;
    cursor: pointer;
    background-color: var(--primary-default);
    border-radius: 12px;
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    font-family: "Roboto", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.eRtgxg:disabled {
    cursor: not-allowed;
    background-color: var(--primary-disabled);
    animation: 0s ease 0s 1 normal none running none;
}

.dgheGf {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 12px;
    min-height: 80px;
    height: auto;
    border: 1px solid transparent;
    background-color: rgb(255, 255, 255);
    border-color: rgb(212, 218, 224);
    padding: 14px 58px 14px 18px;
}

.fGeCMc {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    max-height: 24px;
}

.jUrtDM {
    font-family: "Roboto", sans-serif;
    color: rgb(69, 78, 87);
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    max-height: 40px;
}

.clONfs {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 18px;
    width: 32px;
    height: 32px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.bmiMessage {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
}

.bmiMessage p {
    font-size: 14px;
    font-weight: 500;
}

.goalMessage {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    background: var(--surface-secondary);
}

.goalMessage p {
    font-size: 12px;
}

.goalMessage h6 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
}

.FeedbackSummaryContainer-module-container--\+pMXq {
    margin: 0 auto;
    max-width: 375px;
    padding: 30px var(--default-indent) 124px;
}

.Title-module-h2--T8W2Z {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
}

.Title-module-default--X8sDL {
    color: var(--default-text-color);
}

.Common-module-mb-xs2--dyDYl {
    margin-bottom: 12px;
}

.FeedbackSummaryContainer-module-bmiWrapper--g05O- {
    border-radius: 12px;
    box-shadow: 0 2px 6px rgb(0 0 0/8%), 0 2px 34px rgb(0 0 0/5%);
}

.FeedbackSummaryContainer-module-bmiHeader--aVAkG {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
    padding: 12px 12px 3px;
}

.Text-module-default--9nb4F {
    color: var(--default-text-color);
    font-weight: 500;
}

.Text-module-mediumSmall--JWE3s {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.Text-module-bold--DRNtQ {
    font-weight: 700;
}

.Text-module-caption--MeFDb {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.Text-module-grey--mn9R0 {
    color: var(--text-secondary) !important;
}

.FeedbackSummaryContainer-module-bmiChartWrapper--fkLxi {
    display: flex;
    justify-content: center;
    position: relative;
}

.FeedbackSummaryContainer-module-bmiChart--S63zJ {
    left: 21px;
    position: absolute;
    right: 21px;
    top: 28px;
}

.FeedbackSummaryContainer-module-dotWrapper--CfNHs {
    animation: FeedbackSummaryContainer-module-movingDot--9fgYl 3s ease-in;
    height: 25px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
}

@keyframes FeedbackSummaryContainer-module-movingDot--9fgYl {
    0% {
        left: 0;
    }
}

.FeedbackSummaryContainer-module-bmiTooltip--uTHrx {
    background-color: var(--default-text-color);
    border-radius: 4px;
    left: 50%;
    padding: 4px 11px;
    position: absolute;
    top: 0;
    transform: translate(-50%, calc(-100% - 10px));
    white-space: nowrap;
}

.FeedbackSummaryContainer-module-bmiTooltip--uTHrx:after {
    background-color: var(--default-text-color);
    border-radius: 0;
    bottom: 0;
    content: "";
    height: 8px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 4px) rotate(45deg);
    width: 8px;
}

.Text-module-normal--evGFf {
    font-weight: 400;
}

.Text-module-white--2Bq0\+ {
    color: var(--white-color);
}

.FeedbackSummaryContainer-module-bmiDot--0eCby {
    background: hsla(48, 95%, 85%, .7);
    border: 2px solid #f1d148;
    border-radius: 50%;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
}

.FeedbackSummaryContainer-module-bmiDot--0eCby:after {
    background: #f1d148;
    border-radius: 50%;
    content: "";
    height: 15px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
}

.FeedbackSummaryContainer-module-bmiTitles--1C30n {
    align-items: center;
    bottom: 11px;
    display: flex;
    justify-content: space-around;
    position: absolute;
    width: 100%;
}

.Text-module-letterSpacing1--2438o {
    letter-spacing: 1px;
}

.Text-module-uppercase--Dcjqx {
    text-transform: uppercase;
}

.Text-module-extraSmall--EPJug {
    font-size: 14px;
    line-height: 20px;
}

.Text-module-bold--DRNtQ {
    font-weight: 700;
}

.FeedbackSummaryContainer-module-bodyWrapper--5\+-VY {
    display: flex;
    justify-content: space-between;
    padding: 16px 0 12px var(--default-indent);
}

.FeedbackSummaryContainer-module-bmiField--Wsqp7:not(:last-child) {
    margin-bottom: 16px;
}

.FeedbackSummaryContainer-module-bmiField--Wsqp7 {
    align-items: end;
    display: flex;
    justify-content: flex-start;
}

.FeedbackSummaryContainer-module-iconBox--0X-9t {
    margin-right: 12px;
}

.FeedbackSummaryContainer-module-modelWrapper--Xyl49 {
    max-width: 130px;
}

.FeedbackSummaryContainer-module-messageWrapper--Ww\+-x {
    background-color: var(--surface-secondary);
    border-radius: 12px;
    display: flex;
    padding: 6px 8px 8px;
}

.FeedbackSummaryContainer-module-emojiPart--EZYMQ {
    display: block;
    font-size: 20px;
    height: fit-content;
    margin-right: 4px;
}

.FixedWrapper-module-fixedWrapperContainer--JAz\+c {
    align-items: center;
    background: linear-gradient(0deg, var(--background-color) 0, var(--background-color) 65%, hsla(0, 0%, 100%, 0) 100%);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    margin: 0 auto;
    max-width: 560px;
    min-height: 124px;
    padding: 48px var(--default-indent) 8px;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 99;
}

.FixedWrapper-module-innerContainer--9r6Kl {
    max-width: 100%;
    width: 100%;
}

._container_113ff_1 {
    max-width: 608px;
    margin: 0 auto;
    padding: 30px var(--default-indent) 90px;
}

@media screen and (min-width: 768px) {
    ._contentWrapper_113ff_58 {
        padding: 0 16px;
    }
}

.dFJukc {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 24px;
}

._titleHighlight_113ff_7 {
    color: var(--primary-default);
}

.dWbqTF {
    font-family: "Roboto", sans-serif;
    color: rgb(69, 78, 87);
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
}

.fsnLlE {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 21px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 12px;
}

._underline_113ff_11 {
    text-decoration: underline;
}

.kfVqAo {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px;
    border-radius: 12px;
}

._graphicWrapper_113ff_15 {
    padding-top: 24px;
    padding-bottom: 44px;
}

._graphicWrapper_113ff_15,
._monthsBox_113ff_16 {
    max-width: 400px;
    margin: 0 auto;
    position: relative;
}

._container_1h2ox_1 {
    max-width: 279px;
    position: relative;
    margin: 0 auto;
}

._graphicAnimationWrapper_1h2ox_7 {
    max-width: 0;
    overflow: hidden;
    animation: _graphicAppear_1h2ox_1 1.5s forwards;
}

@keyframes _graphicAppear_1h2ox_1 {
    100% {
        max-width: 298px;
    }
}

.gzDPPi {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
}

._image_1h2ox_13 {
    min-height: 152px;
    min-width: 279px;
}

._tipWrapperStart_1h2ox_33 {
    top: 0;
    left: 0;
    transform: translate(-45%);
}

._tipWrapper_1h2ox_23 {
    position: absolute;
    opacity: 0;
    animation: _tipAppear_1h2ox_1 .7s forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

@keyframes _tipAppear_1h2ox_1 {
    100% {
        opacity: 1;
    }
}

._tip_2dkun_1 {
    display: flex;
    width: fit-content;
    padding: 4px 8px;
    background-color: var(--text-main);
    border-radius: 4px;
    position: relative;
}

.mZkjN {
    font-family: "Roboto", sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

._unit_2dkun_21 {
    margin-left: 3px;
}

._substractWrapper_2dkun_10 {
    max-width: 16px;
    height: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    transform: translateY(-100%);
    position: absolute;
}

._buttonStart_1h2ox_127 {
    border: 3px solid #f38888;
}

._buttonMain_1h2ox_120 {
    width: 18px;
    height: 18px;
    background: var(--surface-main);
    border-radius: 50%;
}

._tipWrapper_1h2ox_23 {
    position: absolute;
    opacity: 0;
    animation: _tipAppear_1h2ox_1 .7s forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

._tip_2dkun_1 {
    display: flex;
    width: fit-content;
    padding: 4px 8px;
    background-color: var(--text-main);
    border-radius: 4px;
    position: relative;
}

._substractWrapper_2dkun_10 {
    max-width: 16px;
    height: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    transform: translateY(-100%);
    position: absolute;
}

._buttonSecondary_1h2ox_135 {
    position: relative;
    width: 18px;
    height: 18px;
    background: rgba(255 255 255 / 70%);
    border-radius: 50%;
}

._buttonSecondary_1h2ox_135:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

._tipWrapper_1h2ox_23 {
    position: absolute;
    opacity: 0;
    animation: _tipAppear_1h2ox_1 .7s forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

._tip_2dkun_1 {
    display: flex;
    width: fit-content;
    padding: 4px 8px;
    background-color: var(--text-main);
    border-radius: 4px;
    position: relative;
}

._substractWrapper_2dkun_10 {
    max-width: 16px;
    height: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    transform: translateY(-100%);
    position: absolute;
}

._buttonSecondary_1h2ox_135 {
    position: relative;
    width: 18px;
    height: 18px;
    background: rgba(255 255 255 / 70%);
    border-radius: 50%;
}

._buttonSecondary_1h2ox_135:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

._tipWrapper_1h2ox_23 {
    position: absolute;
    opacity: 0;
    animation: _tipAppear_1h2ox_1 .7s forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

._tipWrapperSecond_1h2ox_51 {
    top: 36px;
    left: 82px;
    animation-delay: .5s;
}

._tipWrapperPenultimate_1h2ox_82 {
    top: 54px;
    left: 151px;
    animation-delay: 1s;
}

._tipWrapperFinish_1h2ox_106 {
    top: 82px;
    right: 0;
    transform: translate(45%);
    animation-delay: 1.5s;
}

._buttonFinish_1h2ox_131 {
    border: 3px solid var(--primary-active-green);
}

._buttonMain_1h2ox_120 {
    width: 18px;
    height: 18px;
    background: var(--surface-main);
    border-radius: 50%;
}

._graphicWrapper_113ff_15 {
    max-width: 400px;
    margin: 0 auto;
    position: relative;
}

._monthsBox_113ff_16 {
    display: flex;
    padding: 0 25px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 18px;
    width: 100%;
    justify-content: space-between;
    max-width: 400px;
    margin: 0 auto;
}

.huIcJP {
    font-family: "Roboto", sans-serif;
    color: rgb(134, 150, 166);
    font-size: 12px;
    line-height: 16px;
}

._monthsBox_113ff_16>* {
    font-weight: 400;
}

._buttonWrapper_113ff_42 {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    max-width: 432px;
    padding: 44px 16px 20px;
    background: linear-gradient(0deg, var(--surface-main) 0%, var(--surface-main) 65%, rgba(255 255 255 / 0%) 100%);
}

@media screen and (min-width: 768px) {
    ._buttonWrapper_113ff_42 {
        position: static;
    }
}

.gzfFEq {
    font-family: "Roboto", sans-serif;
    color: var(--primary-default);
    text-align: center;
    font-size: 40px;
    line-height: 52px;
    font-weight: 700;
    margin-bottom: 24px;
}

._title_ppgpe_23 p {
    margin-top: 4px;
}

.bIsBmN {
    font-family: "Roboto", sans-serif;
    color: var(--primary-default);
    text-align: center;
    font-size: 18px;
    line-height: 28px;
}

._textComponent_1hhyz_11 {
    font-size: 40px;
    font-weight: 700;
}

.hGmRKm {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-size: 14px;
    line-height: 20px;
}

._svgComponent_1hhyz_1 {
    fill: transparent;
}

._animatedCircle_1hhyz_5 {
    transform: rotate(-90deg);
    transform-origin: center;
    stroke: transparent;
    stroke-dasharray: 446;
    stroke-dashoffset: 446;
    animation: loadingWheelAnimation 15s linear forwards;
}

@keyframes loadingWheelAnimation {
    to {
        stroke-dashoffset: 0;
    }
}

._textComponent_1hhyz_11 {
    font-size: 40px;
    font-weight: 700
}

._sliderWrapper_igrbr_1 {
    left: 50%;
    transform: translate(-50%);
    width: calc(100% + var(--default-indent-double));
    margin-bottom: 80px
}

._image_igrbr_8 {
    width: auto;
    height: 100%;
    max-height: 406px;
    margin: 0 auto
}

.slick-slider {
    width: 100%;
    margin-bottom: 40px
}

.slick-slide {
    transition: .5s
}

.slick-current {
    opacity: 1;
    transition: .5s
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: none
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translateZ(0)
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    transition: transform 0.5s ease-in-out;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: ""
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.gePvOI svg {
    width: 100%;
    height: 100%;
    fill: rgb(254, 225, 75);
}

.fMvoXk {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

.eHtHFU {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-size: 16px;
    line-height: 24px;
}

._wrapper_fqbso_1 {
    padding: 15px;
    border-radius: 12px;
    border: 1px solid var(--border-default);
    box-sizing: border-box;
    margin: 0 6px 12px
}

._author_fqbso_9 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    justify-content: space-between
}

._authorLeft_fqbso_16 {
    display: flex
}

._photo_fqbso_20 {
    width: 24px;
    height: 24px;
    margin-right: 10px
}

._mark_fqbso_26 {
    display: flex;
    gap: 4px;
    margin-left: auto
}

._animationWrapper_ppgpe_1 {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 32px 0 0;
    max-width: 600px
}

._textAnimation_ppgpe_13 {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 8px;
    margin-bottom: 52px
}

._textAnimationItem_ppgpe_22,
._titleAnimated_ppgpe_23 {
    position: absolute;
    padding: 0 30px;
    opacity: 0;
    animation: _textApear_ppgpe_1 15s forwards;
    width: fit-content;
    text-align: center
}

._titleAnimated_ppgpe_23 {
    animation-name: _textApearTitle_ppgpe_1
}

._circleAnimation_ppgpe_36 {
    max-width: 330px
}

._prBarWrapper_ppgpe_40>* {
    transform: scale(.8)
}

._sliderContainer_ppgpe_44 {
    width: 100%;
    position: relative
}

._subTitle_ppgpe_49 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: var(--primary-active);
    margin-bottom: 24px
}

._title_ppgpe_23 {
    padding: 0 16px
}

._title_ppgpe_23 p {
    margin-top: 4px
}

@keyframes _textApear_ppgpe_1 {
    0% {
        opacity: .5;
        top: 6px
    }

    5% {
        opacity: 1;
        top: 0
    }

    98% {
        opacity: 1;
        top: 0
    }

    to {
        opacity: 0;
        top: -20px
    }
}

@keyframes _textApearTitle_ppgpe_1 {
    0% {
        opacity: .5;
        top: 6px
    }

    20% {
        opacity: 1;
        top: 0
    }

    to {
        opacity: 1;
        top: 0
    }
}

._container_1mft2_1 {
    margin: 0 auto;
    max-width: 448px
}

._inputContainer_1mft2_6 {
    width: 100%;
    height: 54px;
    display: flex;
    position: relative;
    margin-bottom: 8px;
    align-items: center
}

.jhnkTu {
    padding-top: 30px;
    padding-left: 16px;
    padding-right: 16px;
}

._colorBrand_824fg_76 {
    color: var(--primary-default) !important;
}

._input_1mft2_6 {
    width: 100%;
    height: 54px;
    font-size: 16px;
    transition: .3s;
    line-height: 22px;
    border-radius: 12px;
    padding: 0 24px 0 40px;
    box-sizing: border-box;
    color: var(--text-secondary);
    border: 1px solid var(--border-default);
    background: var(--surface-default)
}

._emailIcon_1mft2_29 {
    position: absolute;
    left: 13px;
    z-index: 10;
}

._emailForm_1mft2_34 {
    width: 100%
}

._submitInput_1mft2_38 {
    position: absolute;
    left: -999px;
    width: 0;
    height: 0;
    opacity: 0
}

._input_1mft2_6:focus {
    border-color: var(--text-secondary);
    background-color: var(--surface-main);
    z-index: 9;
}

._errorInput_1mft2_51 {
    border-color: var(--critical-default);
    background-color: var(--critical-surface)
}

._errorInput_1mft2_51:focus {
    border-color: var(--critical-default);
    background-color: var(--critical-surface)
}

._errorIco_1mft2_61 {
    width: 16px;
    height: 16px;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    border-radius: 50%;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    color: var(--critical-default);
    border: 2px solid var(--critical-default);
    opacity: 0
}

._successIco_1mft2_76 {
    opacity: 0;
    width: 16px;
    height: 16px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    border: 2px solid var(--success-default)
}

._inputIcon_1mft2_88 {
    right: 12px;
    position: absolute;
    background-color: transparent
}

._errorWrapper_1mft2_94 {
    width: 100%;
    padding: 0 12px;
    min-height: 16px;
    margin-bottom: 16px
}

._disclaimerContainer_1mft2_101 {
    display: flex;
    align-items: center;
    margin-bottom: 24px
}

._lockIco_1mft2_107 {
    padding: 5px;
    margin-right: 5px;
    min-width: fit-content
}

._errorInput_1mft2_51~._inputIcon_1mft2_88 ._errorIco_1mft2_61 {
    opacity: 1
}

._validInput_1mft2_117~._inputIcon_1mft2_88 ._successIco_1mft2_76 {
    opacity: 1
}

._checkboxWrapper_1mft2_121 {
    display: flex;
    align-items: center;
    margin-bottom: 20px
}

.kjlNE {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    max-width: 22px;
}

._bannerWrapper_1062y_1 {
    padding: 13px 15px;
    display: flex;
    border-radius: 12px;
    align-items: center;
    margin-bottom: 56px;
    position: relative
}

._bannerWrapper_1062y_1 * {
    position: relative;
    z-index: 2
}

._bannerWrapper_1062y_1:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: var(--success-surface);
    position: absolute;
    left: 0;
    z-index: 1
}

._promoIcon_1062y_27 {
    min-width: 36px;
    margin-right: 17px
}

._customIcoWrapper_1062y_32 {
    flex-shrink: 0;
    max-width: 64px;
    margin-right: 9px
}

._oldPrice_1062y_38 {
    color: #90a1b2
}

._guaranteeContainer_d5kzv_1 {
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 16px 32px
}

._guaranteeContainer_d5kzv_1 * {
    position: relative;
    z-index: 2
}

._guaranteeContainer_d5kzv_1:after {
    content: "";
    display: block;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 1 !important;
    background-color: var(--surface-secondary)
}

._imageBox_d5kzv_26 {
    max-width: 180px;
    margin: 0 auto 12px
}

._link_d5kzv_31 {
    display: block;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: var(--text-secondary);
    text-decoration: underline;
    opacity: .8
}

._copyright_d5kzv_41 {
    margin-top: 12px
}

._underline_d5kzv_45 {
    text-decoration: underline
}

._footerLink_d5kzv_49 {
    display: block;
    margin-bottom: 18px;
    color: var(--text-secondary-subdued);
    text-align: center;
    word-break: break-word;
    font-size: 14px;
    line-height: 24px
}

._rootTopBanner_1arkq_1 {
    width: 100%;
    height: 56px;
    padding: 0 var(--default-indent);
    margin: 0 auto;
    background-color: transparent;
    z-index: 105;
    transition: .25s;
    position: fixed;
    left: 0;
    right: 0;
    color: var(--text-secondary);
    margin-top: -60px;
}

._contentContainer_1arkq_15 {
    position: relative;
    top: 0;
    width: 100%;
    height: 56px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    transition: .3s
}

._contentContainerHidden_1arkq_28 {
    top: -65px
}

._userIcon_1arkq_32 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-left: 14px;
    border-radius: 50%;
    background: var(--border-default)
}

._discount_1arkq_44 {
    margin-right: auto
}

._timerBox_1arkq_48 {
    margin-right: auto;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700
}

._sticked_1arkq_55 {
    background-color: var(--surface-main);
    box-shadow: 0 4px 8px #00000014;
    transition: .1s
}

._progressBox_1arkq_61 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    background-color: #d4dae07f
}

._progressBar_1arkq_71 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--primary-default);
    transition: width .2s linear
}

._logoWrapper_1arkq_80 {
    margin-right: auto
}

._button_1arkq_84 {
    max-width: 160px !important;
    min-height: 44px !important
}

._speechBubble_lp9ax_1 {
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 18px;
    padding: 12px 16px;
    border-radius: 12px;
    background: var(--warning-highlight)
}

._speechBubble_lp9ax_1:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 14px;
    height: 14px;
    background: var(--warning-highlight);
    transform: translate(-50%, -50%) rotate(-45deg)
}

._preselected_lp9ax_24 {
    background: var(--success-surface)
}

._preselected_lp9ax_24:after {
    background: var(--success-surface)
}

._showBubble_lp9ax_32 {
    display: flex
}

._planContainer_1e0ga_1 {
    margin-bottom: 24px
}

._planItem_1e0ga_5 {
    position: relative;
    margin-bottom: 16px
}

._planInput_1e0ga_10 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10
}

._planBox_1e0ga_21 {
    display: flex;
    border-color: var(--border-default);
    border-style: solid;
    transition: .2s linear
}

._planLeft_1e0ga_28 {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 13px;
    border-width: 2px 0 2px 2px;
    border-style: inherit;
    border-color: inherit;
    border-radius: 12px 0 0 12px
}

._planRight_1e0ga_39 {
    width: 100%;
    max-width: 90px;
    padding: 12px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 0 12px 12px 0;
    border-style: solid;
    border-color: inherit
}

._planPrice_1e0ga_53 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: var(--text-secondary)
}

._planPerWeek_1e0ga_61 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: var(--text-secondary-subdued)
}

._planItem_1e0ga_5:not(._recurrent_1e0ga_69) ._planInputChecked_1e0ga_69~._planBox_1e0ga_21 {
    border-color: var(--primary-default)
}

._withSpeechBubble_1e0ga_73._second_1e0ga_73 ._planInputChecked_1e0ga_69~._planBox_1e0ga_21 {
    border-color: var(--safe)
}

._planItem_1e0ga_5:not(._recurrent_1e0ga_69) ._planInputChecked_1e0ga_69~._planBox_1e0ga_21 ._planRight_1e0ga_39 {
    background-color: var(--primary-default)
}

._planItem_1e0ga_5:not(._recurrent_1e0ga_69) ._planInputChecked_1e0ga_69~._planBox_1e0ga_21 ._planPrice_1e0ga_53,
._planItem_1e0ga_5:not(._recurrent_1e0ga_69) ._planInputChecked_1e0ga_69~._planBox_1e0ga_21 ._planPerWeek_1e0ga_61 {
    color: var(--surface-main)
}

._planOldPrice_1e0ga_86 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #8696a699;
    position: relative
}

._planOldPrice_1e0ga_86:after {
    content: "";
    position: absolute;
    width: 35px;
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-22deg);
    background-color: var(--text-secondary)
}

._planItem_1e0ga_5:not(._recurrent_1e0ga_69) ._planInputChecked_1e0ga_69+._planBox_1e0ga_21 ._planOldPrice_1e0ga_86 {
    color: var(--surface-main);
    opacity: .6
}

._planItem_1e0ga_5:not(._recurrent_1e0ga_69) ._planInputChecked_1e0ga_69+._planBox_1e0ga_21 ._planOldPrice_1e0ga_86:after {
    background-color: var(--surface-main)
}

._planItem_1e0ga_5:nth-child(2) ._planOldPrice_1e0ga_86:after,
._recurrent_1e0ga_69 ._planOldPrice_1e0ga_86:after {
    background-color: var(--discount)
}

._planSave_1e0ga_120,
._planSavePromo_1e0ga_121 {
    position: absolute;
    top: 0;
    left: 15px;
    transform: translateY(-50%);
    background-color: var(--primary-default);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--surface-main);
    padding: 0 15px;
    border-radius: 100px
}

._planHint_1e0ga_135 {
    white-space: pre;
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translate(-50%);
    margin: 0 auto;
    overflow: hidden;
    padding: 0 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: var(--text-secondary);
    opacity: 0;
    border-radius: 12px;
    transition: .1s linear
}

._planItem_1e0ga_5:not(._recurrent_1e0ga_69) ._planInputChecked_1e0ga_69~._planHint_1e0ga_135 {
    opacity: .8
}

._planItem_1e0ga_5:not(:first-child, ._recurrent_1e0ga_69) ._planInputChecked_1e0ga_69~._planBox_1e0ga_21 {
    margin-bottom: 38px
}

._oldPrice_1e0ga_162 {
    text-decoration: line-through;
    margin-right: 4px
}

._planSavePromo_1e0ga_121 {
    background-color: var(--discount);
    color: var(--surface-main)
}

._onePricePlanContainer_1e0ga_172 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px
}

._onePricePlanContainerEnd_1e0ga_179 {
    align-items: flex-end;
    margin-bottom: 32px
}

._onePricePlanHeaderSmall_1e0ga_184 {
    font-size: 24px
}

._leftOnePrice_1e0ga_188 {
    font-size: 24px;
    line-height: 32px;
    max-width: 50%
}

._leftOnePriceContainer_1e0ga_194 h1 {
    font-size: 24px;
    line-height: 32px
}

._rightOnePrice_1e0ga_199 {
    display: flex;
    flex-direction: column;
    align-items: flex-end
}

._specialOfferHint_1e0ga_205 {
    width: fit-content;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    background: var(--discount);
    border-radius: 100px;
    padding: 2px 10px;
    color: var(--surface-main);
    margin-bottom: var(--default-indent);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    display: flex
}

._onePrice_1e0ga_172 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px
}

._price_1e0ga_229 {
    padding: 4px 6px;
    border-radius: 8px;
    color: var(--surface-main);
    background-color: var(--primary-default)
}

._oldOnePrice_1e0ga_236 {
    position: relative;
    margin-right: 10px
}

._oldOnePrice_1e0ga_236:after {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    bottom: 25%;
    left: 0;
    background-color: var(--text-secondary);
    transform: rotate(-15deg);
    transform-origin: left
}

._greyHint_1e0ga_254 {
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 8px
}

._greyHintOpacity_1e0ga_260 {
    color: #d4dae0
}

._colorBrand_1e0ga_264 {
    color: var(--primary-default)
}

._second_1e0ga_73 ._planLeft_1e0ga_28 {
    border-radius: 0 0 0 12px;
    border-width: 0 0 2px 2px
}

._second_1e0ga_73 ._planRight_1e0ga_39 {
    border-width: 0 2px 2px 0;
    border-radius: 0 0 12px
}

._mostPopular_1e0ga_278 {
    width: 100%;
    display: block;
    text-align: center;
    background-color: var(--primary-default);
    color: var(--surface-main);
    padding: 2px 0;
    border-radius: 10px 10px 0 0
}

._trialLabel_1e0ga_288 {
    width: 100%;
    display: block;
    text-align: center;
    background-color: var(--safe);
    color: var(--surface-main);
    padding: 7px 0;
    border-radius: 10px 10px 0 0
}

._recurrent_1e0ga_69 ._planSave_1e0ga_120,
._recurrent_1e0ga_69 ._leftPartCaption_1e0ga_299 {
    display: none
}

._recurrent_1e0ga_69 ._planRight_1e0ga_39 {
    max-width: initial;
    background-color: transparent !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-left-width: 0;
    padding-right: 7px
}

._recurrent_1e0ga_69 ._planPrice_1e0ga_53 {
    display: flex;
    line-height: initial;
    align-items: flex-start;
    position: relative;
    padding-right: 16px
}

._priceBigPart_1e0ga_321 svg {
    position: absolute;
    z-index: -1;
    right: 0
}

._priceBigPart_1e0ga_321 {
    font-weight: 700;
    font-size: 42px;
    margin-top: -5px
}

._recurrent_1e0ga_69 ._planPerWeek_1e0ga_61 {
    position: absolute;
    left: 37px;
    bottom: 5px;
    margin: 17px 0 0;
    font-size: 8px;
    line-height: 18px;
    font-weight: 400;
    color: var(--text-secondary)
}

._recurrent_1e0ga_69 ._planOldPrice_1e0ga_86 {
    margin: auto 19px 0 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400
}

._recurrent_1e0ga_69 ._planOldPrice_1e0ga_86:after {
    transform: none;
    width: 110%;
    left: -6%
}

._recurrent_1e0ga_69 ._planBox_1e0ga_21 {
    border-color: var(--primary-default);
    filter: grayscale(100);
    min-height: 84px;
    height: auto;
    background-color: var(--surface-secondary);
    border-radius: 10px;
    opacity: .5
}

._recurrent_1e0ga_69 ._planLeftPlanName_1e0ga_367 {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: flex;
    align-items: center
}

._recurrent_1e0ga_69 ._planLeftPlanName_1e0ga_367:before {
    content: "";
    position: relative;
    display: block;
    min-width: 24px;
    width: 24px;
    height: 24px;
    background-color: var(--surface-secondary);
    border-radius: 50%;
    border: 2px solid var(--primary-default);
    box-sizing: border-box;
    margin-right: 10px;
    box-shadow: inset 0 0 0 3px var(--surface-secondary);
    transition: .3s
}

._withSpeechBubble_1e0ga_73._second_1e0ga_73 ._planLeftPlanName_1e0ga_367:before {
    content: "";
    position: relative;
    display: block;
    min-width: 24px;
    width: 24px;
    height: 24px;
    background-color: var(--surface-secondary);
    border-radius: 50%;
    border: 2px solid var(--safe);
    box-sizing: border-box;
    margin-right: 10px;
    box-shadow: inset 0 0 0 3px var(--surface-secondary);
    transition: .3s
}

._recurrent_1e0ga_69 ._planInputChecked_1e0ga_69~._planBox_1e0ga_21 {
    filter: grayscale(0);
    background-color: transparent;
    opacity: 1
}

._recurrent_1e0ga_69 ._planInputChecked_1e0ga_69~._planBox_1e0ga_21 ._planLeftPlanName_1e0ga_367:before {
    background-color: var(--primary-default);
    box-shadow: inset 0 0 0 4px var(--surface-main)
}

._withSpeechBubble_1e0ga_73._second_1e0ga_73 ._planInputChecked_1e0ga_69~._planBox_1e0ga_21 ._planLeftPlanName_1e0ga_367:before {
    background-color: var(--safe);
    box-shadow: inset 0 0 0 4px var(--surface-main)
}

._planContainer_1eiib_1 {
    margin-bottom: 24px
}

._planItem_1eiib_5 {
    position: relative;
    margin-bottom: 16px
}

._planInput_1eiib_10 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10
}

._planBox_1eiib_21 {
    display: flex;
    border-color: var(--border-default);
    border-style: solid;
    transition: .2s linear
}

._planLeft_1eiib_28 {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 13px;
    border-width: 2px 0 2px 2px;
    border-style: inherit;
    border-color: inherit;
    border-radius: 12px 0 0 12px
}

._planRight_1eiib_39 {
    width: 100%;
    max-width: 90px;
    padding: 12px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 0 12px 12px 0;
    border-style: solid;
    border-color: inherit
}

._planPrice_1eiib_53 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: var(--text-secondary)
}

._planPerWeek_1eiib_61 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: var(--text-secondary-subdued)
}

._planItem_1eiib_5:not(._recurrent_1eiib_69) ._planInputChecked_1eiib_69~._planBox_1eiib_21 {
    border-color: var(--primary-default)
}

._withSpeechBubble_1eiib_73._second_1eiib_73 ._planInputChecked_1eiib_69~._planBox_1eiib_21 {
    border-color: var(--safe)
}

._planItem_1eiib_5:not(._recurrent_1eiib_69) ._planInputChecked_1eiib_69~._planBox_1eiib_21 ._planRight_1eiib_39 {
    background-color: var(--primary-default)
}

._planItem_1eiib_5:not(._recurrent_1eiib_69) ._planInputChecked_1eiib_69~._planBox_1eiib_21 ._planPrice_1eiib_53,
._planItem_1eiib_5:not(._recurrent_1eiib_69) ._planInputChecked_1eiib_69~._planBox_1eiib_21 ._planPerWeek_1eiib_61 {
    color: var(--surface-main)
}

._planOldPrice_1eiib_86 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #8696a699;
    position: relative
}

._planOldPrice_1eiib_86:after {
    content: "";
    position: absolute;
    width: 35px;
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-22deg);
    background-color: var(--text-secondary)
}

._planItem_1eiib_5:not(._recurrent_1eiib_69) ._planInputChecked_1eiib_69+._planBox_1eiib_21 ._planOldPrice_1eiib_86 {
    color: var(--surface-main);
    opacity: .6
}

._planItem_1eiib_5:not(._recurrent_1eiib_69) ._planInputChecked_1eiib_69+._planBox_1eiib_21 ._planOldPrice_1eiib_86:after {
    background-color: var(--surface-main)
}

._planItem_1eiib_5:nth-child(2) ._planOldPrice_1eiib_86:after,
._recurrent_1eiib_69 ._planOldPrice_1eiib_86:after {
    background-color: var(--discount)
}

._planSave_1eiib_120,
._planSavePromo_1eiib_121 {
    position: absolute;
    top: 0;
    left: 15px;
    transform: translateY(-50%);
    background-color: var(--primary-default);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--surface-main);
    padding: 0 15px;
    border-radius: 100px
}

._planHint_1eiib_135 {
    white-space: pre;
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translate(-50%);
    margin: 0 auto;
    overflow: hidden;
    padding: 0 8px;
    background-color: var(--hint-bg);
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: var(--text-secondary);
    opacity: 0;
    border-radius: 12px;
    transition: .1s linear
}

._planItem_1eiib_5:not(._recurrent_1eiib_69) ._planInputChecked_1eiib_69~._planHint_1eiib_135 {
    opacity: .8
}

._planItem_1eiib_5:not(:first-child, ._recurrent_1eiib_69) ._planInputChecked_1eiib_69~._planBox_1eiib_21 {
    margin-bottom: 38px
}

._oldPrice_1eiib_163 {
    text-decoration: line-through;
    margin-right: 4px
}

._planSavePromo_1eiib_121 {
    background-color: var(--discount);
    color: var(--surface-main)
}

._onePricePlanContainer_1eiib_173 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px
}

._onePricePlanContainerEnd_1eiib_180 {
    align-items: flex-end;
    margin-bottom: 32px
}

._onePricePlanHeaderSmall_1eiib_185 {
    font-size: 24px
}

._leftOnePrice_1eiib_189 {
    font-size: 24px;
    line-height: 32px;
    max-width: 50%
}

._leftOnePriceContainer_1eiib_195 h1 {
    font-size: 24px;
    line-height: 32px
}

._rightOnePrice_1eiib_200 {
    display: flex;
    flex-direction: column;
    align-items: flex-end
}

._specialOfferHint_1eiib_206 {
    width: fit-content;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    background: var(--discount);
    border-radius: 100px;
    padding: 2px 10px;
    color: var(--surface-main);
    margin-bottom: var(--default-indent);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    display: flex
}

._onePrice_1eiib_173 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px
}

._price_1eiib_230 {
    padding: 4px 6px;
    border-radius: 8px;
    color: var(--surface-main);
    background-color: var(--primary-default)
}

._oldOnePrice_1eiib_237 {
    position: relative;
    margin-right: 10px
}

._oldOnePrice_1eiib_237:after {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    bottom: 25%;
    left: 0;
    background-color: var(--text-secondary);
    transform: rotate(-15deg);
    transform-origin: left
}

._greyHint_1eiib_255 {
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 8px
}

._greyHintOpacity_1eiib_261 {
    color: #d4dae0
}

._colorBrand_1eiib_265 {
    color: var(--primary-default)
}

._second_1eiib_73 ._planLeft_1eiib_28 {
    border-radius: 0 0 0 12px;
    border-width: 0 0 2px 2px
}

._second_1eiib_73 ._planRight_1eiib_39 {
    border-width: 0 2px 2px 0;
    border-radius: 0 0 12px
}

._mostPopular_1eiib_279 {
    width: 100%;
    display: block;
    text-align: center;
    background-color: var(--primary-default);
    color: var(--surface-main);
    padding: 2px 0;
    border-radius: 10px 10px 0 0
}

._trialLabel_1eiib_289 {
    width: 100%;
    display: block;
    text-align: center;
    background-color: var(--safe);
    color: var(--surface-main);
    padding: 7px 0;
    border-radius: 10px 10px 0 0
}

._recurrent_1eiib_69 ._planSave_1eiib_120,
._recurrent_1eiib_69 ._leftPartCaption_1eiib_300 {
    display: none
}

._recurrent_1eiib_69 ._planRight_1eiib_39 {
    max-width: initial;
    background-color: transparent !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-left-width: 0;
    padding-right: 7px
}

._recurrent_1eiib_69 ._planPrice_1eiib_53 {
    display: flex;
    line-height: initial;
    align-items: flex-start;
    position: relative;
    padding-right: 16px
}

._priceBigPart_1eiib_322 svg {
    position: absolute;
    z-index: -1;
    right: 0
}

._priceBigPart_1eiib_322 {
    font-weight: 700;
    font-size: 42px;
    margin-top: -5px
}

._recurrent_1eiib_69 ._planPerWeek_1eiib_61 {
    position: absolute;
    left: 37px;
    bottom: 5px;
    margin: 17px 0 0;
    font-size: 8px;
    line-height: 18px;
    font-weight: 400;
    color: var(--text-secondary)
}

._recurrent_1eiib_69 ._planOldPrice_1eiib_86 {
    margin: auto 19px 0 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400
}

._recurrent_1eiib_69 ._planOldPrice_1eiib_86:after {
    transform: none;
    width: 110%;
    left: -6%
}

._recurrent_1eiib_69 ._planBox_1eiib_21 {
    border-color: var(--primary-default);
    filter: grayscale(100);
    min-height: 84px;
    height: auto;
    background-color: var(--surface-secondary);
    border-radius: 10px;
    opacity: .5
}

._recurrent_1eiib_69 ._planLeftPlanName_1eiib_368 {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: flex;
    align-items: center
}

._recurrent_1eiib_69 ._planLeftPlanName_1eiib_368:before {
    content: "";
    position: relative;
    display: block;
    min-width: 24px;
    width: 24px;
    height: 24px;
    background-color: var(--surface-secondary);
    border-radius: 50%;
    border: 2px solid var(--primary-default);
    box-sizing: border-box;
    margin-right: 10px;
    box-shadow: inset 0 0 0 3px var(--surface-secondary);
    transition: .3s
}

._withSpeechBubble_1eiib_73._second_1eiib_73 ._planLeftPlanName_1eiib_368:before {
    content: "";
    position: relative;
    display: block;
    min-width: 24px;
    width: 24px;
    height: 24px;
    background-color: var(--surface-secondary);
    border-radius: 50%;
    border: 2px solid var(--safe);
    box-sizing: border-box;
    margin-right: 10px;
    box-shadow: inset 0 0 0 3px var(--surface-secondary);
    transition: .3s
}

._recurrent_1eiib_69 ._planInputChecked_1eiib_69~._planBox_1eiib_21 {
    filter: grayscale(0);
    background-color: transparent;
    opacity: 1
}

._recurrent_1eiib_69 ._planInputChecked_1eiib_69~._planBox_1eiib_21 ._planLeftPlanName_1eiib_368:before {
    background-color: var(--primary-default);
    box-shadow: inset 0 0 0 4px var(--surface-main)
}

._withSpeechBubble_1eiib_73._second_1eiib_73 ._planInputChecked_1eiib_69~._planBox_1eiib_21 ._planLeftPlanName_1eiib_368:before {
    background-color: var(--safe);
    box-shadow: inset 0 0 0 4px var(--surface-main)
}

._userInfo_mf72k_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px
}

._userInfoItem_mf72k_8 {
    display: flex;
    flex-direction: column;
    flex-grow: 1
}

._userInfoItem_mf72k_8:first-of-type {
    width: 41%;
    padding-right: 24px;
    border-right: 1px solid var(--border-default);
    align-items: flex-end
}

._userInfoItem_mf72k_8:last-of-type {
    width: 50%;
    padding-left: 24px
}

._userInfoData_mf72k_26 {
    display: flex
}

._userInfoIcon_mf72k_30 {
    margin-right: 8px
}

._timerContainer_mf72k_34 {
    max-width: 395px;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto 24px
}

.oTTWo {
    font-size: 13px;
}

._productsBlockWrapper_mf72k_41 {
    max-width: 400px;
    margin: 0 auto
}

._disclaimerWrapper_mf72k_46 {
    max-width: 536px;
    margin: 0 auto
}

._pricesWrapper_mf72k_51 {
    margin-bottom: 31px
}

._guaranteeLink_mf72k_55 {
    color: var(--primary-default);
    text-decoration: underline
}

._colorBrand_mf72k_60 {
    color: var(--primary-default) !important
}

._imgBox_mf72k_64 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 356px;
    margin: 0 auto 76px;
    min-height: 24px;
    padding: 0 16px
}

._imgWrapper_mf72k_75 img {
    width: auto;
    height: 24px
}

._shieldIco_mf72k_80 {
    display: inline;
    position: relative;
    top: 3px;
    margin-left: 7px
}

._capitalizeText_mf72k_87 {
    text-transform: capitalize
}

@media screen and (min-width: 768px) {
    ._imgBox_mf72k_64 {
        max-width: 432px
    }
}

._background_15z6m_1 {
    padding: 24px 16px 40px !important;
    max-width: 768px;
    margin: 0 auto;
    background: #fff;
    background-size: cover;
    height: auto;
    min-height: 100vh
}

._modalBody_15z6m_11 {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto
}

._headersBlock_15z6m_18 {
    position: relative;
    padding: 0 16px;
    background: var(--primary-active);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px
}

._promoLabel_15z6m_26 {
    position: absolute;
    top: 0;
    left: 0;
    width: 78px;
    height: 79px
}

._promoLabelInfo_15z6m_34 {
    margin: 16px auto 24px;
    max-width: 160px
}

._headersBlock_15z6m_18:after {
    content: "";
    position: absolute;
    bottom: -16px;
    right: 0;
    left: 0;
    width: 100%;
    height: 16px;
    background-image: linear-gradient(to top left, transparent 50%, var(--primary-active) 0), linear-gradient(to bottom left, var(--primary-active) 50%, transparent 0);
    background-size: 16px 16px;
    background-repeat: repeat
}

._modalContent_15z6m_53 {
    flex-grow: 1;
    overflow-y: auto;
    padding: 32px 16px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: var(--surface-default)
}

._pricesBlock_15z6m_62 {
    display: flex;
    justify-content: center;
    align-items: center
}

._price_15z6m_62 {
    padding: 8px 0 16px;
    font-size: 48px;
    line-height: 56px;
    font-weight: 700
}

._optionContentContainer_h8trs_1 {
    max-width: 100%
}

._optionContent_h8trs_1,
._optionContentPreInit_h8trs_6 {
    position: relative;
    width: 100%;
    transition: .1s
}

._optionContentPreInit_h8trs_6 {
    opacity: 0;
    height: 0;
    overflow: hidden
}

._optionContentContainer_h8trs_1._paypal_h8trs_18 ._optionContentPreInit_h8trs_6[data-option=paypal],
._optionContentContainer_h8trs_1._card_h8trs_19 ._optionContentPreInit_h8trs_6[data-option=card],
._optionContentContainer_h8trs_1._apple_h8trs_20 ._optionContentPreInit_h8trs_6[data-option=ApplePayOption],
._optionContentContainer_h8trs_1._google_h8trs_21 ._optionContentPreInit_h8trs_6[data-option=GooglePayOption] {
    opacity: 1;
    height: auto
}

._optionContentContainer_h8trs_1._paypalOption_h8trs_26 ._optionContentPreInit_h8trs_6[data-option=paypal] {
    margin-top: auto
}

._line_axidb_1 {
    height: 2px;
    width: 32px;
    background-color: var(--border-default);
    border-radius: 10px;
    margin: 0 auto;
    position: absolute;
    top: 18px;
    left: 0;
    right: 0
}

._separator_axidb_13 {
    width: 100%;
    height: 1px;
    margin-bottom: 16px;
    background: var(--border-default)
}

._overlay_axidb_20 {
    background: rgba(255 255 255 / 80%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999
}

._overlayLoader_axidb_27 {
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: 200px;
    font-size: 50px
}

._input_axidb_35 {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0
}

._optionsButtonWrapper_axidb_42 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px
}

._label_axidb_48 {
    width: calc(51% - calc(var(--default-indent) / 2))
}

._customLabel_axidb_52 {
    width: calc(32.63% - calc(var(--default-indent) / 8))
}

._centered_axidb_56 {
    justify-content: center
}

._paymentOptionButton_axidb_60 {
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1px var(--border-default);
    border-radius: 12px;
    transition: .3s
}

._paymentOptionButtonDropdown_axidb_70 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 56px;
    margin-bottom: 8px;
    padding: 16px;
    box-shadow: inset 0 0 0 1px var(--border-default);
    border-radius: 12px;
    transition: .3s;
    overflow: hidden
}

._customLabel_axidb_52 ._paymentOptionButton_axidb_60 {
    height: 78px
}

._paymentOptionButton_axidb_60 img {
    width: 90%;
    max-width: 168px;
    margin: 0 auto;
    filter: saturate(0%);
    transition: .3s
}

._paymentOptionButtonDropdown_axidb_70 img {
    margin-left: 5px;
    filter: saturate(0%);
    transition: .3s
}

._paymentOptionButton_axidb_60._applePay_axidb_102 img,
._paymentOptionButton_axidb_60._googlePay_axidb_106 img {
    width: 78px
}

._totalPriceWrapper_axidb_110 {
    display: flex;
    justify-content: space-between
}

._pricesWrapper_axidb_115 {
    margin-bottom: 16px
}

._summaryPricesWrapper_axidb_119 {
    padding: 12px 0;
    border: 1px solid var(--border-default);
    border-width: 1px 0;
    margin-bottom: 18px
}

._summaryPricesWrapperSmall_axidb_126 {
    padding: 8px 0;
    border: 1px solid var(--border-default);
    border-width: 1px 0;
    margin-bottom: 18px
}

._summaryPricesWrapper_axidb_119 *,
._summaryPricesWrapperSmall_axidb_126>* {
    display: flex;
    justify-content: space-between
}

._numbers_axidb_139 {
    color: var(--critical-default)
}

._totalPricesWrapper_axidb_143 {
    background-color: var(--surface-default);
    padding: 12px 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px
}

._input_axidb_35:checked+._paymentOptionButton_axidb_60,
._input_axidb_35:checked+._paymentOptionButtonDropdown_axidb_70 {
    box-shadow: inset 0 0 0 2px #49d461
}

._input_axidb_35:checked+._paymentOptionButton_axidb_60 *,
._input_axidb_35:checked+._paymentOptionButtonDropdown_axidb_70 * {
    filter: none
}

._colorBlock_axidb_162 {
    color: var(--primary-default)
}

._totalPrice_axidb_110 {
    color: var(--text-secondary);
    font-weight: 700
}

._youSavedWrapper_axidb_171 {
    text-align: right
}

._perStateWrapper_axidb_175 {
    background-color: var(--success-surface);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    margin-bottom: 8px
}

._refundInfoWrapper_axidb_185 {
    margin-top: 16px
}

._timerContainer_axidb_189 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10
}

._customModalClose_axidb_197 {
    position: absolute;
    top: 44px;
    right: 16px
}

._customModalClose_axidb_197._timerEnd_axidb_203 {
    top: 16px
}

._timer_axidb_189 {
    padding-top: 4px;
    padding-bottom: 4px
}

._timer_axidb_189 svg {
    transform: scale(.55);
    margin-right: 0
}

._timer_axidb_189 p {
    font-size: 12px
}

._modalContent_axidb_221 {
    padding-top: 44px
}

._modalContent_axidb_221._timerEnd_axidb_203 {
    padding-top: 16px
}

._modalOverlay_axidb_229 {
    background: rgba(0 0 0 / 50%)
}

._fullPriceStrikeout_axidb_233 {
    text-decoration: line-through;
    color: var(--text-secondary-subdued)
}

._labelsWrapper_axidb_238 {
    display: flex;
    flex-direction: column
}

._labelThird_axidb_243 {
    order: 3
}

._optionHeader_axidb_247 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    margin-bottom: 16px;
    padding-left: 36px
}

._cardOptionHeader_axidb_257 {
    margin-bottom: 8px
}

._optionHeader_axidb_247:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--border-default)
}

._optionHeader_axidb_247:after {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--primary-default);
    opacity: 0
}

._optionContent_axidb_284 {
    display: none
}

._input_axidb_35:checked+._paymentOptionButtonDropdown_axidb_70 {
    height: auto;
    box-shadow: inset 0 0 0 2px var(--text-secondary)
}

._input_axidb_35:checked+._paymentOptionButton_axidb_60 ._optionContent_axidb_284,
._input_axidb_35:checked+._paymentOptionButtonDropdown_axidb_70 ._optionContent_axidb_284 {
    display: block
}

._input_axidb_35:checked+._paymentOptionButton_axidb_60 ._optionHeader_axidb_247:before,
._input_axidb_35:checked+._paymentOptionButtonDropdown_axidb_70 ._optionHeader_axidb_247:before {
    border-color: var(--primary-default)
}

._input_axidb_35:checked+._paymentOptionButton_axidb_60 ._optionHeader_axidb_247:after,
._input_axidb_35:checked+._paymentOptionButtonDropdown_axidb_70 ._optionHeader_axidb_247:after {
    opacity: 1
}

._paymentOptionButton_axidb_60 *[id*=solid-payment-form-iframe],
._paymentOptionButtonDropdown_axidb_70 *[id*=solid-payment-form-iframe] {
    display: block;
    width: 100%;
    margin: 0 auto
}

._imagesWrapper_axidb_315 {
    display: flex
}

._payPal_axidb_319 {
    max-width: 100%;
    min-height: 54px;
    margin: 0 auto;
    background: #ffc439;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center
}

._payPalWithMargin_axidb_330 {
    margin-bottom: 8px
}

._payButton_axidb_334 {
    position: relative;
    height: 54px;
    margin-top: 12px;
    border-radius: 8px;
    overflow: hidden
}

._payButtonDisable_axidb_342 {
    display: none
}

._commentFooter_1smw0_5 {
    display: flex;
    align-items: center;
    padding: 0 25px
}

._rateBox_1smw0_11 {
    display: flex;
    align-items: center;
    margin-right: 16px
}

._rateBox_1smw0_11 svg {
    margin-right: 4px
}

._commentTextBox_1smw0_21 {
    position: relative;
    padding-left: 19px;
    margin-bottom: 16px
}

._commentTextBox_1smw0_21:before {
    content: "“";
    position: absolute;
    left: -1px;
    top: -7px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: var(--text-main)
}

._commentListItem_1smw0_39:not(:last-child) {
    margin-bottom: 44px
}

._sliderInner_1kzpx_1 {
    left: 50%;
    transform: translate(-50%);
    width: calc(100% + var(--default-indent-double));
    margin-bottom: 80px
}

._image_1kzpx_8 {
    width: auto;
    height: 100%;
    max-height: 415px;
    margin: 0 auto
}

._dot_1kzpx_15 {
    display: block;
    height: 8px;
    width: 8px;
    background-color: var(--border-default);
    border-radius: 50%
}

.sliderWrapper .slick-slide+.slick-current {
    opacity: 1;
    transform: scale(1);
    transition: .5s;
    z-index: 300;
    margin: 0
}

.sliderSubWrapper {
    opacity: 1;
    transition: 1s ease-out;
    z-index: 300;
    margin: 0 -16px 56px
}

.sliderSubWrapper .slick-slide {
    opacity: 1
}

.sliderWrapper .slick-slider .slick-list .slick-track {
    display: flex;
    align-items: center;
    min-height: 415px
}

.sliderWrapper .slick-slider .slick-arrow {
    transform: none;
    bottom: -36px;
    top: auto;
    z-index: 400
}

.sliderWrapper .slick-slider .slick-prev {
    left: 10%
}

.sliderWrapper .slick-slider .slick-prev:before {
    content: ""
}

.sliderWrapper .slick-slider .slick-next {
    right: 7%
}

.sliderWrapper .slick-slider .slick-next:before {
    content: " "
}

.sliderWrapper .slick-slider .slick-dots {
    bottom: -31px
}

.sliderWrapper .slick-slider .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0 6px 0 0
}

.sliderWrapper .slick-slider .slick-dots .slick-active {
    width: 20px;
    height: 8px;
    border-radius: 100px
}

.sliderWrapper .slick-slider .slick-dots li:last-of-type {
    margin-right: 0
}

.sliderWrapper .slick-slider .slick-dots .slick-active span {
    width: 20px;
    height: 8px;
    border-radius: 100px;
    background: var(--primary-default)
}

.slick-slide {
    transform: scale(.8);
    transition: .5s
}

.slick-slide div {
    height: 100%
}

@media (min-width: 768px) {
    .sliderWrapper .slick-slider .slick-prev {
        left: 25%
    }

    .sliderWrapper .slick-slider .slick-next {
        right: 23%
    }
}

.slick-loading .slick-list {
    background: #fff;
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translateY(-50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25
}

.slick-prev:before,
.slick-next:before {
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-prev {
    left: -25px
}

[dir=rtl] .slick-prev {
    right: -25px;
    left: auto
}

.slick-prev:before {
    content: "←"
}

[dir=rtl] .slick-prev:before {
    content: "→"
}

.slick-next {
    right: -25px
}

[dir=rtl] .slick-next {
    right: auto;
    left: -25px
}

.slick-next:before {
    content: "→"
}

[dir=rtl] .slick-next:before {
    content: "←"
}

.slick-dotted.slick-slider {
    margin-bottom: 30px
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1
}

.slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: .25;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #000
}

._ratingWrapper_b3vu4_1 {
    max-width: 400px;
    margin: 0 auto;
}

.bottlesWrapper {
    width: 100%;
}

._ratingImage_b3vu4_6 {
    margin: 0 auto;
    width: 100%;
}

.fYPuyR {
    font-family: "Roboto", sans-serif;
    color: var(--primary-default);
    text-align: center;
    font-size: 40px;
    line-height: 52px;
    font-weight: 700;
}

.bcMEAP {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 21px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 8px;
}

.jNAvYo {
    font-family: "Roboto", sans-serif;
    color: rgb(134, 150, 166);
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 32px;
}


._beforeAfterContent_n3hxm_1 {
    margin-bottom: 16px;
    background: var(--surface-default);
    border-radius: 12px
}

._beforeAfterRow_n3hxm_7 {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center
}

._beforeAfterItem_n3hxm_14 {
    position: relative;
    flex-grow: 1;
    padding: 12px
}

._beforeAfterRow_n3hxm_7:first-of-type ._beforeAfterItem_n3hxm_14:first-of-type:before {
    content: "";
    position: absolute;
    top: 8px;
    bottom: 8px;
    right: -1px;
    width: 2px;
    background: var(--surface-main)
}

._beforeAfterRow_n3hxm_7:nth-of-type(2) {
    background: radial-gradient(85% 85% at 50% 50%, #fefefe 26.94%, #dde1e6 100%)
}

._beforeAfterRow_n3hxm_7:nth-of-type(2) ._beforeAfterItem_n3hxm_14 {
    padding: 0
}

._beforeAfterRow_n3hxm_7:nth-of-type(3) ._beforeAfterItem_n3hxm_14:first-of-type:after {
    content: "";
    position: absolute;
    top: 8px;
    bottom: 0;
    right: -1px;
    width: 2px;
    background: var(--surface-main)
}

._beforeAfterRow_n3hxm_7:nth-of-type(3) ._beforeAfterItem_n3hxm_14:before {
    content: "";
    position: absolute;
    right: 15px;
    bottom: 0;
    left: 15px;
    height: 1px;
    background: var(--surface-main)
}

._beforeAfterRow_n3hxm_7:last-of-type ._beforeAfterItem_n3hxm_14:first-of-type:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 8px;
    right: -1px;
    width: 2px;
    background: var(--surface-main)
}

._arrowImage_n3hxm_68 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 271px;
}

._userInfoImage_n3hxm_76 {
    margin: 0 auto
}

._levelWrapper_n3hxm_80 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px
}

._level_n3hxm_80 {
    width: 24px;
    height: 4px;
    background: var(--surface-main)
}

._greenLevel_n3hxm_94 {
    width: 24px;
    height: 4px;
    background: var(--primary-default)
}

@media (min-width: 768px) {
    ._beforeAfterItem_n3hxm_14 {
        padding: 12px 20px
    }
}

._container_1u1wn_1 {
    overflow: hidden;
    padding: 30px var(--default-indent) 0;
    max-width: 600px;
    margin: 0 auto
}

.gyUCBj {
    position: relative;
    cursor: pointer;
    background-color: var(--primary-default);
    border-radius: 8px;
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 4px 16px;
    max-width: 180px;
    min-height: 50px;
    word-break: break-word;
    animation: 2s ease 0s infinite normal none running pulsar;
    box-shadow: var(--primary-default) 0px 0px 0px 0px;
}

._button_1arkq_84 {
    max-width: 160px !important;
    min-height: 44px !important;
}

.hFvwKz {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 16px;
}

.UsumM {
    font-family: "Roboto", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.gWSVqW {
    font-family: "Roboto", sans-serif;
    color: rgb(69, 78, 87);
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
}

.hIXbnm {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
}

.liyZAe {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    max-width: 24px;
}

.iHpzLz,
.brDyJQ {
    position: relative;
    cursor: pointer;
    background-color: var(--primary-default);
    border-radius: 12px;
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 20px;
    min-height: 64px;
    margin-bottom: 24px;
    word-break: break-word;
    animation: 2s ease 0s infinite normal none running pulsar;
    box-shadow: var(--primary-default) 0px 0px 0px 0px;
}

.iedPVB {
    font-family: "Roboto", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

._timerContainer_mf72k_34 {
    align-items: center;
    background: var(--promotion-color);
    color: var(--white-color);
    display: flex;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    line-height: 28px;
    padding: 10px var(--default-indent);
    transition: .3s;
    width: 100%;
    z-index: 100;
    border-radius: 8px;
    margin: 0 auto 24px;
}

._root_14go4_1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

._icon_14go4_20 {
    margin-right: 8px;
}

.gXqlvo {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 24px;
}

.gLQPYe {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 32px;
}

.dpJoKR {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
}

.bvnrcB {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
}

._list_x5yh5_1 {
    list-style: none;
}

._newTextStyle_x5yh5_15 {
    margin-bottom: 16px;
    padding-left: 36px;
    font-size: 18px;
    line-height: 28px;
    color: var(--text-main);
    position: relative;
}

._newTextStyle_x5yh5_15 svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: -1px;
    left: 0;
    margin-top: unset;
}

.resultsImg {
    width: 100%;
}

.bdhtoB {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 34px;
}

._wrapper_1smw0_1 {
    padding-bottom: 56px;
    list-style: none;
}

._commentListItem_1smw0_39:not(:last-child) {
    margin-bottom: 44px;
}

.lcIcsa {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-size: 18px;
    line-height: 28px;
}

._rateBox_1smw0_11 {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.btcOML {
    font-family: "Roboto", sans-serif;
    color: rgb(134, 150, 166);
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 58px;
}

.hCLVRs {
    font-family: "Roboto", sans-serif;
    color: rgb(41, 46, 51);
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
    font-weight: 500;
}

/* Results Page New */

.results__header {
    width: 100%;
    height: 56px;
    padding: 0 var(--default-indent);
    margin: 0 auto;
    background-color: transparent;
    z-index: 105;
    transition: .25s;
    left: 0;
    right: 0;
    color: var(--text-secondary);
    margin-top: -60px;
}

.results__headerContainer {
    margin: 0 auto;
}

.results__timerBar {
    font-family: "Poppins", sans-serif;
    background: #ffc0ad;
    color: #00203e;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1.875px;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
}

.results__h3 {
    font-family: "Syne", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    position: relative;
    margin-bottom: 20px;

}

.results__h3 i {
    color: #4441d9;
}

.results__reviewBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}

.results__reviewBar h6 {
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.results__planSystem {
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    position: relative;
    z-index: 1;
}

.planSystem__item {
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    margin-bottom: 12px;
    margin-right: 8px;
    padding: 8px;
    text-align: center;
    width: 60%;
}

.planSystem__item h3 {
    color: #4441d9;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 3.33333px;
    line-height: 12px;
    margin-bottom: 4px;
    text-transform: uppercase;
}

.planSystem__item p {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin: 0;
}

.results__p {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-bottom: 40px;
}

.results__p b {
    font-weight: 600;
}

.results__weightGraph {
    position: relative;
    margin-bottom: 40px;
}

.results__weightGraph .weightGraph__graphImages {
    overflow: hidden;
    padding-left: 50px;
    padding-right: 80px;
    padding-top: 40px;
    position: relative;
}

.weightGraph__graphOverlay {
    animation: graphReveal 3s ease-in-out;
    background: #fff;
    bottom: 0;
    height: 130%;
    left: 0;
    position: absolute;
    transform: translateX(100%);
    width: 100%;
    z-index: 999;
}

@keyframes graphReveal {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

.weightGraph__lines {
    display: flex;
    flex-direction: column;
    height: calc(100% + 4px);
    justify-content: space-between;
    left: 0;
    padding: 40px 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.weightGraph__line {
    border: none;
    border-bottom: 1px solid #d8d8d8;
}

.weightGraph__goal--start {
    animation: popReveal .5s ease forwards;
    animation-delay: 1.2s;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 15px;
    min-width: 70px;
    opacity: 0;
    position: absolute;
    width: 70px;
    z-index: 900;
}

@keyframes popReveal {
    0% {
        opacity: 0;
        transform: scale(.8);
    }

    50% {
        opacity: 1;
        transform: scale(1.1);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.weightGraph__goal--finish {
    align-items: flex-end;
    animation: popReveal .5s ease forwards;
    animation-delay: 2.2s;
    bottom: 0;
    display: flex;
    height: 130%;
    justify-content: center;
    opacity: 0;
    padding-bottom: 8%;
    position: absolute;
    right: 13px;
    width: 138px;
    z-index: 900;
}

.weightGraph__pop--start {
    background: #ffc1ad;
    border: none;
    border-radius: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 70px;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 900;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}

.weightGraph__pop--finish {
    background: #4441d9;
    border: 4px solid #fff;
    border-radius: 46px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: .8rem 0;
    position: relative;
    width: 100%;
    z-index: 900;
}

.weightGraph__popNumber--start {
    align-items: center;
    color: #00203e;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 700;
    justify-content: center;
    line-height: 14px;
    text-transform: uppercase;
}

.weightGraph__popNumber--start b {
    font-size: 24px;
    line-height: 29px;
}

.weightGraph__popNumber--finish {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-transform: uppercase;
    text-align: center;
}

.weightGraph__popNumber--finish b {
    font-size: 28px;
    line-height: 34px;
    margin-right: 4px;
}

.weightGraph__icon {
    bottom: 100%;
    font-size: 32px;
    left: 50%;
    line-height: 37px;
    position: absolute;
    transform: translateX(-50%);
}

.weightGraph__popInfo {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
}

.weightGraph__image--front {
    position: absolute;
    top: 46px;
    width: calc(100% - 130px);
    z-index: 800;
}

.weightGraph__image--back {
    position: relative;
    top: 4px;
    width: 100%;
    z-index: 700;
}

.weightGraph__dates {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding-left: 40px;
    padding-right: 24px;
    padding-top: 8px;
    width: 100%;
}

.weightGraph__dates h5 {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    margin: 0;
}

.weightGraph__dates span {
    background: #4441d9;
    border-radius: 8px;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    padding: 6px 16px;
    text-align: center;
}

.results__h4 {
    color: #00203e;
    font-family: "Syne", sans-serif;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -.56px;
    line-height: 110%;
    margin-bottom: 24px;
}

.results__h4 span {
    color: #4441d9;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    text-decoration-style: wavy;
}

.results__goals {
    margin-bottom: 40px;
}

.goals__list {
    list-style-type: none;
    margin-bottom: 40px
}

.goals__listItem {
    display: flex;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
    font-family: "Poppins", sans-serif;
}

.goals__listItem svg {
    margin-right: 16px;
    margin-top: 5px;
    min-width: 11px;
}

.goals__phone {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    position: relative;
}

.goals__phone h3 {
    color: #00203e;
    font-family: "Syne", sans-serif;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -.56px;
    line-height: 110%;
    margin-bottom: 16px;
}

.goals__phone p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Poppins", sans-serif;
}

.goals__phoneImage {
    margin: 0;
    min-width: 200px;
    width: 250px;
}

.results__included {
    background: #fff;
    border: 1px solid #d4d4d4;
    border-radius: 16px;
    padding: 32px 16px;
    margin-bottom: 40px;
}

.results__includedItem {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
}

.results__includedItem img {
    margin-left: 0;
    margin-right: 18px;
    min-width: 65px;
    width: 65px;
}

.results__included h3 {
    color: #00203e;
    font-family: "Syne", sans-serif;
    font-size: 28px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
    text-align: center;
}

.results__included h4 {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    line-height: 20px;
}

.plans h3 {
    font-family: "Syne", sans-serif;
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
}

.plans__timer {
    display: flex;
    flex-direction: row;
}

.plans h6 {
    font-weight: 400;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    text-align: center;
    margin-bottom: 40px;
}

.plans__options {
    margin-bottom: 20px;
}

.plans__item {
    display: flex;
    font-family: "Poppins", sans-serif;
    background: #fff;
    border: 2px solid #d4d4d4;
    border-radius: 8px;
    color: #0a0021;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 30px 15px;
    position: relative;
    transition: .2s;
}

.plans__bonus {
    position: absolute;
    top: -30px;
    left: -5px;
    z-index: 2;
}

.plans__bonus img {
    max-width: 40px;
    rotate: 346deg;
    filter: opacity(0.2);
}

.plans__item.active>.plans__bonus img {
    filter: opacity(1);
}

.plans__item--top {
    padding-top: 38px;
}

.plans__item.active {
    background: #fff;
    border: 2px solid #4441d9;
    border-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(78, 0, 255, .16);
    color: #18184a;
}

.plans__leftInfo {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.plans__radio {
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border: 1px solid #dfdfdf;
    border-radius: 100%;
    position: relative;
    transition: .2s;
}

.plans__item.active>.plans__leftInfo .plans__radio {
    background: #18184a !important;
    border-color: #18184a !important;
}

.plans__radio:after {
    content: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' fill='none'%3E%3Cpath fill='%23fff' d='M11.25 1.5c0 .21-.094.398-.234.54l-6 6a.73.73 0 0 1-.516.21.74.74 0 0 1-.54-.21l-3-3a.74.74 0 0 1-.21-.54.74.74 0 0 1 .75-.75c.188 0 .375.094.516.234L4.5 6.445 9.96.985A.76.76 0 0 1 10.5.75c.398 0 .75.328.75.75'/%3E%3C/svg%3E);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.plans__discountBanner {
    background: #ffc1ad;
    border-radius: 6px 6px 0 0;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 700;
    left: 0;
    padding: 1px 8px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 100%;
    z-index: 1;
}

.plans__leftInfo h4 {
    color: #00203e;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
}

.plans__leftInfo p {
    color: #00203e;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.plans__item.active>.plans__leftInfo p {
    color: #4441d9;
}

.plans__rightInfo {
    align-items: center;
    display: flex;
}

.plans__item s {
    color: #00203e;
    font-size: 20px;
    line-height: 24px;
    margin-right: 10px;
    text-decoration-line: line-through;
}

.plans__item.active s {
    color: #4441d9;
}

.plans__price {
    line-height: 38px;
    font-weight: 500;
    margin-right: 6px;
    font-size: 32px;
}

.plans__pricePer {
    font-size: 10px;
    line-height: 1em;
    font-weight: 400;
}

.plans__button {
    animation: pulsar 1.5s infinite;
    box-shadow: rgb(67 65 217) 0px 0px 0px 0px;
    cursor: pointer;
    background: #4441d9;
    font-family: "Poppins", sans-serif;
    border: none;
    border-radius: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 24px;
    margin: auto;
    padding: 30px 20px;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 40px;
}

.plans__guarantee {
    align-items: center;
    display: flex;
    font-family: "Poppins", sans-serif;
}

.plans__guarantee img {
    margin-right: 28px;
}

.plans__guarantee h4 {
    color: #00203e;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -.5px;
    line-height: 24px;
    margin: 0 0 8px;
}

.plans__guarantee p {
    color: #00203e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.badges__container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px 0;
}

.badges__container img {
    max-width: 100%;
}

.faq {
    background-color: #0c003e;
    font-family: "Poppins", sans-serif;
}

.faq h3 {
    color: #ffc1ad;
    font-family: "Syne", sans-serif;
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
}

.faq__container {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 25px;
}

.faq__item {
    border-bottom: 1px solid #b18fff;
}

.faq__question {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: space-between;
    line-height: 24px;
    padding: 20px 0;
}

.faq__answer {
    color: #fff;
    font-size: 16px;
    line-height: 1.5em;
    padding: 20px 0;
}

.faq__item .arrow {
    min-width: 24px;
    transition: .3s;
}

.faq__item .arrow.active {
    transform: rotate(45deg);
}

.videos__container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 50px 25px;
    ;
}

.videos h3 {
    color: #18184a;
    font-family: "Syne", sans-serif;
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center;
}

.videos h3 i {
    color: #4441d9;
}

.videos p {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    text-align: center;
}

.video-wrapper {
    position: relative;
}

.videos video {
    border-radius: 12px;
}

.slick-slide div {
    height: auto;
}

.slick-dots li {
    background: #18184a;
    border-radius: 100%;
    height: 9px;
    transition: .3s;
    width: 9px;
}

.slick-dots li.slick-active {
    background: #4441d9;
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
    opacity: 0 !important;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 50%;
}

.video-slide video {
    display: block;
    width: 100%;
    z-index: 0;
}

.reviews {
    background-color: #18184a;
}

.reviews__container {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 25px;
    ;
}

.reviews__item {
    border: 1px solid #d4d4d4;
    border-radius: 16px;
    color: #fff;
    margin: 20px 0;
    padding: 30px 16px 30px 24px;
}

.reviews__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.reviews__title h3 {
    font-family: "Syne", sans-serif;
    color: #ffc0ad;
    font-style: italic;
}

.reviews__stars {
    display: flex;
}

.reviews__stars svg {
    margin: 0 2px;
}

.reviews__item p {
    font-family: "Poppins", sans-serif;
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
}

.reviews__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reviews__name h4 {
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.recap {
    font-family: "Poppins", sans-serif;
}

.recap__container {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 15px;
}

.recap__list {
    border: 1px solid #d4d4d4;
    border-radius: 16px;
    margin-bottom: 42px;
    padding: 0 24px 32px;
}

.recap__listHeader {
    background: #ffc1ad;
    border-radius: 16px 16px 0 0;
    color: #00203e;
    font-size: 15px;
    font-weight: 600;
    margin: 0 -24px 32px;
    padding: 10px;
    text-align: center;
}

.recap__list h3 {
    color: #00203e;
    font-family: "Syne", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 32px;
    text-align: center;
}

.recap__list h4 {
    color: #32bdc5;
    font-family: "Syne", sans-serif;
    font-size: 32px;
    font-style: italic;
    text-align: center;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
}

.recap__listBenefit {
    align-items: center;
    display: flex;
    margin-bottom: 24px;
}

.recap__listIcon {
    margin-right: 24px;
    min-width: 24px;
}

.recap__mockup {}

.recap__mockupImage {
    min-width: 214px;
    max-width: 100%;
    text-align: center;
}

.recap__mockupImage img {
    width: 500px;
    max-width: 100%;
}

.recap__mockupHeader {
    min-width: 170px;
    font-family: "Poppins", sans-serif;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recap__mockupTag {
    background: #ffc1ad;
    border-radius: 19px;
    color: #00203e;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 20px;
    padding: 8px 24px;
    white-space: nowrap;
}

.recap__mockupHeader h3 {
    color: #00203e;
    font-family: "Syne", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -.64px;
    line-height: 110%;
    margin-bottom: 14px;
}

.recap__mockupHeader h6 {
    font-weight: 400;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    text-align: center;
    margin-bottom: 20px;
}

.disclaimerFooter {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 25px;
}

.disclaimerFooter__text {
    font-family: "Poppins", sans-serif;
    color: rgb(134, 150, 166);
    font-size: 12px;
    line-height: 16px;
}

/* Mobile */

@media (max-width: 768px) {
    .results__timerBar p {
        font-size: 10px;
    }

    .plans__item {
        display: flex;
        font-family: "Poppins", sans-serif;
        background: #fff;
        border: 2px solid #d4d4d4;
        border-radius: 8px;
        color: #0a0021;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 30px 10px;
        position: relative;
        transition: .2s;
    }

    .plans__radio {
        box-sizing: border-box;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        border: 1px solid #dfdfdf;
        border-radius: 100%;
        position: relative;
        transition: .2s;
    }

    .plans__price {
        font-size: 24px;
    }

    .recap__listHeader {
        font-size: 12px;
    }
}

/* Loading Page */

.loadingBar__container {
    max-width: 600px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 50px 25px;
}


.loadingBar__screen h3 {
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
}

.loadingBar__screen {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.loadingBar__screen.fade-in {
    opacity: 1;
}

.loadingBar__screen.fade-out {
    opacity: 0;
}

.loadingBar__screen.hidden {
    display: none;
}

.scrollingContainer {
    width: 550px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
}

.scrollingImage {
    animation: scrollImage 10s linear infinite;
    height: 200px;
}

#screen2__img {
    max-width: 100%;
}


@keyframes scrollImage {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media (max-width: 768px) {
    .scrollingContainer {
        width: 100%;
    }

    .scrollingImage {
        height: 200px;
        object-fit: cover;
    }
}


.loadingBar__bar {
    width: 100%;
    height: 50px;
    background-color: #c9e3fa;
    position: relative;
}

.loadingBar__progress {
    margin-top: 20px;
    position: sticky;
    bottom: 0;
    background: linear-gradient(0deg, var(--background-color) 0, var(--background-color) 65%, hsla(0, 0%, 100%, 0) 100%);
    padding-top: 20px;
    z-index: 1000;
}


.loadingBar__fill {
    width: 0;
    height: 100%;
    background-color: var(--primary-active);
    transition: width 0.3s ease;
}

.loadingBar__percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #00000087;
    font-size: 1.5em;
}

.loadingBar__text {
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
    text-align: center;
}

.loadingBar__text h5 {
    font-size: 14;
    font-weight: 500;
}

.loadingBar__textItem {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.loadingBar__textItem.fade-in {
    opacity: 1;
}

.loadingBar__textItem.fade-out {
    opacity: 0;
}


/* Mobile */

@media (max-width: 768px) {
    .loadingBar__container {
        max-width: 600px;
        min-height: 100vh;
        margin: 0 auto;
        padding: 20px;
    }
}

/* Stylized Components */

.step__container {
    max-width: 600px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 50px 25px;
    font-family: "Poppins", sans-serif;
}

.step__container h2 {
    font-size: 33px;
    color: #00203e;
    font-family: "Syne", sans-serif;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
}

.step__container h3 {
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    color: #00203e;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
}

.step__container h4 {
    font-size: 22px;
    color: #00203e;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-bottom: 20px;
}

.step__container h5 {
    font-size: 18px;
    color: #00203e;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-bottom: 20px;
}

.step__container h2 span {
    color: #4441d9;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    text-decoration-style: wavy;
}

.answers__container {
    display: block;
}

.answer {
    position: relative;
    margin-bottom: 12px;
}

.answer.selected > .answer__container {
    border-color: var(--primary-default);
    border-width: 2px;
}

.answer__hiddenCheckBox {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}

.answer__container {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 12px;
    min-height: 80px;
    height: auto;
    border: 1px solid transparent;
    background-color: rgb(255, 255, 255);
    border-color: rgb(212, 218, 224);
    padding: 14px 18px;
}

.answer__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.answer__text {
    font-family: "Poppins", sans-serif;
    color: rgb(41, 46, 51);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
    -webkit-line-clamp: 1;
    max-height: 24px;
}

.fixedButton {
    align-items: center;
    background: linear-gradient(0deg, var(--background-color) 0, var(--background-color) 65%, hsla(0, 0%, 100%, 0) 100%);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    margin: 0 auto;
    max-width: 560px;
    min-height: 124px;
    padding: 48px var(--default-indent) 8px;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 99;
}

.fixedButton__container {
    max-width: 100%;
    width: 100%;
}

.fixedButton__button {
    position: relative;
    cursor: pointer;
    background-color: var(--primary-default);
    border-radius: 12px;
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    font-family: "Roboto", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.continueButton {
    position: relative;
    cursor: pointer;
    background-color: var(--primary-default);
    border-radius: 12px;
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    font-family: "Roboto", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.step__container .bigNumber {
    display: block;
    color: var(--primary-default);
    text-align: center;
    font-size: 76px;
    font-weight: 700;
}

.body-level .level {
    background: #2d1d53;
    border-radius: 24px;
    height: 42px;
}

.level>div {
    background: linear-gradient(90deg, #f79c7e, #fcff75);
    border-radius: 24px;
    color: #0a0021;
    display: inline-flex;
    font-size: 22px;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 10px;
    white-space: nowrap;
    /* Ensure text doesn't wrap */
    min-width: 50px;
    transition: width 5s cubic-bezier(1.25, 0.1, 0.25, 1)
    /* Smooth transition */
}

.level>div .tip {
    color: #555;
    font-size: 18px;
    font-weight: 400;
    left: calc(100% - 60px);
    line-height: 22px;
    position: absolute;
    top: calc(100% + 30px);
    white-space: nowrap;
}

.level>div .tip:before {
    border-color: transparent transparent #555;
    border-style: solid;
    border-width: 0 10px 14px;
    bottom: 30px;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
}
.body-level .titles {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
}
.body-level .titles span {
    color: #555555;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 6px;
}


  .checkmark {
    color: var(--primary-default); /* Set checkmark color */
    font-size: 18px;
    margin-left: 10px;
  }
  
.answer__content > span {
    background-color: var(--primary-default);
    border: 1px solid transparent;
    background-image: url(https://dance-bit.com/3d434e942ddb8a1f75a2.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

input:checked + .answer__content > * {
    color: rgb(23, 32, 42);
}

.answer__checkmark {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 8px;
    background-position: center center;
    mix-blend-mode: normal;
    border: 1.5px solid rgb(202, 202, 202);
    transition: 0.2s ease-out;
}

/* Mobile */

@media (max-width: 768px) {
    .step__container h4 {
        font-size: 18px;
    }
}

/* Interstitial 1 */

.comparisonChart__key {
    display: flex;
    margin-bottom: 32px;
    justify-content: center;
}

.key__row  {
    display: flex;
    margin-bottom: 8px;
    font-size: 16px;
    align-items: center;
    color: rgb(0, 32, 62);
}


/* Success Stories */

.banner[data-v-718e372c] {
    background: #eef7ff;
    border-radius: 32px;
    overflow: hidden;
    padding: 24px 32px;
    text-align: center;
    margin-bottom: 32px;
}

.banner h4[data-v-718e372c] {
    color: #4441d9;
    font-family: "Syne", sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
}

.banner .stories[data-v-718e372c] {
    animation: reviewScroll 90s linear infinite;
    display: flex;
    margin-bottom: 24px;
    transform: translateX(0);
    width: -moz-fit-content;
    width: fit-content;
}

.banner .stories img[data-v-718e372c] {
    height: 201px;
    margin-right: 10px !important;
    max-width: inherit;
}

@keyframes reviewScroll {

0% {
    transform: translateX(0);
}
100% {
    transform: translateX(-100%);
}

}